import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Paper,
  Box,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  ArrowUpward,
  ArrowDownward,
  InfoOutlined,
  FilterList,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { PieChart, Pie, Cell } from "recharts";

const viewsData = [
  { date: "Sep 30", views: 0 },
  { date: "Oct 20", views: 1 },
  { date: "Oct 22", views: 2 },
  { date: "Oct 24", views: 0 },
];

const viewTypeData = [
  { name: "Accessory", value: 100 },
  { name: "PopCode", value: 0 },
  { name: "Share", value: 0 },
];

const COLORS = ["#8884d8", "#82ca9d", "#ffc658"];

export default function Analytics() {
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Welcome Vince
          </Typography>
          <Select value={30} size="small" sx={{ minWidth: 120 }}>
            <MenuItem value={30}>Last 30 days</MenuItem>
          </Select>
          <Typography variant="body2" sx={{ ml: 2 }}>
            Sep 30 - Oct 29
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {[
            { title: "Leads Generated", value: 1, change: 100 },
            { title: "Link taps", value: 0, change: 0 },
            { title: "Card Views", value: 4, change: 100 },
            { title: "Contacts Downloaded", value: 3, change: 100 },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 140,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography color="text.secondary" variant="body2">
                    {item.title}
                  </Typography>
                  <InfoOutlined fontSize="small" color="action" />
                </Box>
                <Typography
                  component="p"
                  variant="h4"
                  sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                >
                  {item.value}
                </Typography>
                <Typography
                  color={item.change >= 0 ? "success.main" : "error.main"}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {item.change >= 0 ? <ArrowUpward /> : <ArrowDownward />}
                  {Math.abs(item.change)}%
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Paper sx={{ p: 2, my: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">Views Over Time</Typography>
            <Box>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Accessory (4)"
              />
              <FormControlLabel control={<Checkbox />} label="PopCode (0)" />
              <FormControlLabel control={<Checkbox />} label="Share (0)" />
            </Box>
          </Box>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={viewsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="views"
                stroke={theme.palette.primary.main}
              />
            </LineChart>
          </ResponsiveContainer>
        </Paper>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6">Leads Over Time</Typography>
                <InfoOutlined fontSize="small" color="action" />
              </Box>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={viewsData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="views"
                    stroke={theme.palette.secondary.main}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, height: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6">View Type</Typography>
                <InfoOutlined fontSize="small" color="action" />
              </Box>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={viewTypeData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label
                  >
                    {viewTypeData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <Box sx={{ mt: 2 }}>
                {viewTypeData.map((item, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Box
                      sx={{
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        backgroundColor: COLORS[index % COLORS.length],
                        mr: 1,
                      }}
                    />
                    {item.name}: {item.value}%
                  </Typography>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Typography variant="h6">Recent Activity</Typography>
          <IconButton>
            <FilterList />
          </IconButton>
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          No data available
        </Typography>
      </Box>
    </Box>
  );
}
