import React, { useEffect, useState } from "react";
import * as action from "../../service/action";
import { useUserContext } from "../Login/userContext";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FileSaver from "file-saver";
import { visuallyHidden } from "@mui/utils";
import { makeStyles } from "@material-ui/core";
import Papa from "papaparse";
import { generatVCardForContact } from "../../service/vcard.js";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import {
  Refresh,
  Map as MapIcon,
  Add,
  FileDownload,
  Search,
} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Popover from "@mui/material/Popover";

import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import DeleteContactDialog from "./DeleteContact";
import MapView from "../../components/MapView";

// Add this function at the top of your file, outside of the component
const getColorForLetter = (letter) => {
  const colors = [
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
    "#9E9E9E",
    "#607D8B",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
  ];
  const charCode = letter.toUpperCase().charCodeAt(0) - 65; // A = 0, B = 1, etc.
  return colors[charCode % colors.length];
};

function createData(id, name, phone, email, dateMet, notes) {
  return {
    id,
    name,
    phone,
    email,
    dateMet,
    notes,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "select",
    numeric: false,
    disablePadding: false,
    label: "Select all",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    label: "Contact",
  },
  {
    id: "tag",
    numeric: false,
    disablePadding: false,
    label: "Tag",
  },
  {
    id: "connectedWith",
    numeric: false,
    disablePadding: false,
    label: "Connected with",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "export",
    numeric: false,
    disablePadding: false,
    label: "Export",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal">
            {headCell.id === "select" ? (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all contacts",
                }}
              />
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
          align="center"
        >
          Contacts
        </Typography>
      )}
      <Tooltip
        hidden={numSelected !== 1}
        title="Delete"
        color="primary"
        style={{ width: "20px", marginRight: "20px" }}
      >
        <IconButton onClick={props.handleSaveContact}>
          <SaveIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        hidden={numSelected < 1}
        title="Delete"
        style={{ width: "20px" }}
        color="warning"
      >
        <IconButton onClick={props.handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSaveContact: PropTypes.func.isRequired,
};

const useStyles = makeStyles(styles);

export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState(new Map());
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { getUser } = useUserContext();
  const [userData, setUserData] = React.useState(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  const classes = useStyles();
  const selectedMap = {};
  const [openDeleteContactDialog, setOpenDeleteContactDialog] =
    React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);

  const [showMap, setShowMap] = useState(false);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [startDate, setStartDate] = useState("2022-01-01");
  const [endDate, setEndDate] = useState(getCurrentDate());

  const parseDate = (dateString) => {
    const [month, dayWithSuffix, year] = dateString.split(" ");
    const day = dayWithSuffix.replace(/(st|nd|rd|th)/, "");
    const monthIndex = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].indexOf(month);
    return new Date(year, monthIndex, parseInt(day));
  };

  const fetchData = async () => {
    const token = (await getUser()?.getIdToken()) || "token";
    const userDataResponse = await action.getMe(token);
    setUserData(userDataResponse);
  };

  useEffect(() => {
    userData === undefined ? fetchData() : fetchData();
  }, []);

  const handleExport = () => {
    // Extracting headers from the first object in the data list
    const contacts = userData?.v3_contacts.slice();
    delete contacts["id"];

    const filteredHeaders = [
      "full_name",
      "phone_number",
      "email",
      "date_met",
      "location_name",
      "notes",
    ];

    const fileName = `${userData?.links.firstname}_contacts.csv`;

    // Creating an array of arrays, where each inner array represents a row in the CSV
    const csvData = [
      filteredHeaders,
      ...contacts.map((item) => filteredHeaders.map((header) => item[header])),
    ];

    // Converting the array of arrays to CSV format
    const csv = Papa.unparse(csvData);

    // Creating a Blob and a download link to trigger the download
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName || "export.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(
        "Your browser does not support download functionality. Please try using a different browser."
      );
    }
  };

  if (!userData) {
    return;
  }

  const contacts = userData?.v3_contacts;

  console.log(userData);

  const handleDelete = () => {
    setOpenDeleteContactDialog(true);
  };

  const handleSaveContact = async (selectedContact) => {
    selectedContact.name = selectedContact.full_name;
    selectedContact.phone = selectedContact.phone_number;
    const vcard = await generatVCardForContact(selectedContact);
    const blob = new Blob([vcard], {
      type: "text/vcard;charset=utf-8",
    });
    FileSaver.saveAs(blob, "vcard.vcf");
  };

  const handleUpdateContact = async (contactId, newMetIn, newDateMet) => {
    const data = {
      location_name: newMetIn,
    };

    const token = (await getUser()?.getIdToken()) || "token";
    const response = await action.updateV3Contact(token, contactId, data);

    if (response.status != 200) {
      alert("Could not update contact");
    } else {
      fetchData();
    }
  };

  const deleteContacts = async () => {
    const contactsToKeep = [...userData.contacts];

    for (let contact of contactsToKeep) {
      if (selected.has(contact.id)) {
        delete contactsToKeep[contact.id];
      }
    }

    const filteredContacts = contactsToKeep.filter((contact) => contact);

    for (let i in filteredContacts) {
      delete filteredContacts[i].id;
    }
    const token = (await getUser()?.getIdToken()) || "token";
    const response = await action.updateContact(token, {
      contacts: filteredContacts,
    });
    if (response.status != 201) {
      alert("Could not delete contact(s)");
    } else {
      setUserData(response.data);
      setSelected(new Map());
      setOpenDeleteContactDialog(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Update your filtering logic
  const filteredContacts = contacts.filter((contact) => {
    const contactDate = parseDate(contact.date_met);
    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setDate(end.getDate() + 1); // Set to the beginning of the next day

    return (
      (contact.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        contact.email.toLowerCase().includes(searchQuery.toLowerCase())) &&
      contactDate >= start &&
      contactDate < end // Use < instead of <= since we've moved to the next day
    );
  });

  // Slice the filtered contacts for the current page
  const paginatedContacts = filteredContacts.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = new Map();
      paginatedContacts.forEach((n) => newSelected.set(n.id, n));
      setSelected(newSelected);
    } else {
      setSelected(new Map());
    }
  };

  const handleClick = (event, contact) => {
    const newSelected = new Map(selected);
    if (newSelected.has(contact.id)) {
      newSelected.delete(contact.id);
    } else {
      newSelected.set(contact.id, contact);
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.has(id);

  const handleActionClick = (event, contact) => {
    setAnchorEl(event.currentTarget);
    setSelectedContact(contact);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedContact(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleMapClick = () => {
    setShowMap(!showMap);
  };

  console.log(contacts);

  if (!contacts) {
    return <div>Loading...</div>;
    return;
  }

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "flex-start" : "center",
          mb: 2,
        }}
      >
        <Typography variant="h5" sx={{ mb: isMobile ? 2 : 0 }}>
          Contacts ({filteredContacts.length})
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          <Button
            startIcon={<MapIcon />}
            size={isMobile ? "small" : "medium"}
            onClick={handleMapClick}
          >
            {showMap ? "Hide Map" : "Show Map"}
          </Button>
          {!isMobile && (
            <>
              <TextField
                size="small"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                sx={{ width: 150 }}
              />
              <TextField
                size="small"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                sx={{ width: 150 }}
              />
            </>
          )}
          <Button
            variant="contained"
            startIcon={<FileDownload />}
            size={isMobile ? "small" : "medium"}
            onClick={handleExport}
          >
            Export
          </Button>
        </Box>
      </Box>

      {showMap && (
        <MapView
          contacts={filteredContacts}
          updateContact={handleUpdateContact}
        />
      )}

      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.size > 0 && {
              bgcolor: "#f0f0f0",
            }),
          }}
        >
          {selected.size > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.size} selected
            </Typography>
          ) : (
            <> </>
          )}

          {selected.size > 0 && (
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        </Toolbar>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search name, email or company"
          InputProps={{
            startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ m: 2, width: "calc(100% - 32px)" }}
        />
        <TableContainer>
          <Table size={isMobile ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.size > 0 &&
                      selected.size < paginatedContacts.length
                    }
                    checked={
                      paginatedContacts.length > 0 &&
                      selected.size === paginatedContacts.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell>Contact</TableCell>
                {!isMobile && (
                  <>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell>Met in</TableCell>
                    <TableCell>Date</TableCell>
                  </>
                )}
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedContacts.map((contact) => {
                const isItemSelected = isSelected(contact.id);
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, contact)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={contact.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          sx={{
                            mr: 2,
                            bgcolor: getColorForLetter(
                              contact.full_name.charAt(0)
                            ),
                          }}
                        >
                          {contact.full_name.charAt(0)}
                        </Avatar>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              textTransform: "none",
                              fontWeight: "normal",
                            }}
                          >
                            {contact.full_name}
                          </Typography>
                          {isMobile ? (
                            <>
                              {contact.location_name && (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {contact.location_name}
                                </Typography>
                              )}
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {contact.date_met}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  maxWidth: "200px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {contact.notes}
                              </Typography>
                            </>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              {contact.email}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell>{contact.phone_number}</TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            sx={{
                              maxWidth: "200px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {contact.notes}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Chip label={contact.location_name} size="small" />
                        </TableCell>
                        <TableCell>{contact.date_met}</TableCell>
                      </>
                    )}
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleActionClick(event, contact);
                        }}
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredContacts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <DeleteContactDialog
        open={openDeleteContactDialog}
        contacts={selected}
        deleteContacts={deleteContacts}
        closeDeleteContactDialog={() => setOpenDeleteContactDialog(false)}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Button
            startIcon={<SaveIcon />}
            onClick={() => handleSaveContact(selectedContact)}
          >
            Save Contact
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}
