import React, { useState } from "react";
import { useUserContext } from "./userContext";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  ThemeProvider,
  useTheme,
  Alert,
} from "@mui/material";
import bappaTap from "../../images/bappa-tap.png";
import bappaLogo from "../../images/bappa-logo-black.png";

export default function ResetPasswordPage() {
  const [email, setEmail] = useState("");
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { forgotPassword } = useUserContext();

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setAlertInfo({ show: false, type: "", message: "" }); // Reset alert on new submission

    if (email) {
      try {
        await forgotPassword(email);
        setAlertInfo({
          show: true,
          type: "success",
          message:
            "A password reset has been submitted, please check your email for further instructions",
        });
      } catch (error) {
        console.error("Error resetting password:", error);
        setAlertInfo({
          show: true,
          type: "error",
          message:
            "An error occurred while resetting the password. Please try again.",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="lg"
        sx={{ height: "100vh", display: "flex", alignItems: "center" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
            BappaCards{" "}
            <img
              src={bappaLogo}
              alt="BappaLogo"
              style={{ width: "24px", height: "auto", marginLeft: "4px" }}
            />
          </Typography>
        </Box>
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box sx={{ maxWidth: 500, mx: "auto" }}>
              {alertInfo.show && (
                <Alert
                  severity={alertInfo.type}
                  onClose={() => setAlertInfo({ ...alertInfo, show: false })}
                  sx={{ mb: 2 }}
                >
                  {alertInfo.message}
                </Alert>
              )}
              <Box sx={{ mb: 4 }}>
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                    fontSize: {
                      xs: "2rem",
                      sm: "3rem",
                    },
                  }}
                >
                  Reset your password
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  If you signed up with an email and password, reset your
                  password below.
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  If you signed up using your phone number, Google or Apple, get
                  help accessing your account{" "}
                  <Link href="#" color="secondary">
                    here
                  </Link>
                  .
                </Typography>
              </Box>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="email"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={onSubmit}
                  loading={isLoading}
                  disabled={!email}
                  sx={{
                    mt: 3,
                    mb: 2,
                    bgcolor: theme.palette.primary.main,
                    "&:hover": { bgcolor: theme.palette.secondary.dark },
                  }}
                >
                  Reset password
                </Button>
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <Link
                    onClick={() => navigate("/log-in")}
                    variant="body2"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      color: theme.palette.secondary.main,
                    }}
                  >
                    <span style={{ marginRight: "4px" }}>↩</span> Back to login
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#E6E6FA",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={bappaTap}
              alt="Reset password illustration"
            />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
