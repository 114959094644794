import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import {
  useMediaQuery,
  TextField,
  Button,
  Box,
  List,
  ListItem,
  Typography,
  Paper,
  Autocomplete,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import debounce from "lodash/debounce";

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const EditForm = ({ contact, onSave }) => {
  const [newMetIn, setNewMetIn] = useState(contact.location_name || "");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const fetchSuggestions = async (input) => {
    if (input.length < 3) {
      setSuggestions([]);
      return;
    }
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          input
        )}&addressdetails=1&limit=5`
      );
      const data = await response.json();
      const filteredSuggestions = data.map((item) => {
        if (item.address.country === "United States") {
          return item.address.city
            ? `${item.address.city}, ${item.address.state}`
            : item.address.state;
        } else {
          return item.address.city
            ? `${item.address.city}, ${item.address.country}`
            : item.address.country;
        }
      });
      setSuggestions([...new Set(filteredSuggestions)]); // Remove duplicates
      setShowSuggestions(true);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
  };

  const debouncedFetchSuggestions = debounce(fetchSuggestions, 300);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setNewMetIn(value);
    debouncedFetchSuggestions(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setNewMetIn(suggestion);
    setSuggestions([]);
    setShowSuggestions(false);
  };

  const handleSave = () => {
    onSave(contact.id, newMetIn);
    setSuggestions([]);
    setShowSuggestions(false);
  };

  return (
    <Box sx={{ mt: 2, position: "relative" }}>
      <TextField
        label="New Met in"
        value={newMetIn}
        onChange={handleInputChange}
        fullWidth
        size="small"
        sx={{ mb: 1 }}
        autoFocus
      />
      {showSuggestions && suggestions.length > 0 && (
        <Paper
          sx={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            maxHeight: 200,
            overflow: "auto",
          }}
        >
          <List>
            {suggestions.map((suggestion, index) => (
              <ListItem
                key={index}
                button
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <ListItemText primary={suggestion} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      <Button onClick={handleSave} variant="contained" size="small">
        Save
      </Button>
    </Box>
  );
};

const MapView = ({ contacts, updateContact }) => {
  const [geoContacts, setGeoContacts] = useState([]);
  const [nonGeoContacts, setNonGeoContacts] = useState([]);
  const [editingContactId, setEditingContactId] = useState(null);
  const center = [40, -95]; // Default center of the map
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mapRef = useRef(null);
  const markerClusterRef = useRef(null);

  useEffect(() => {
    const geocodeContacts = async () => {
      const geocodedContacts = await Promise.all(
        contacts.map(async (contact) => {
          if (contact.location_name) {
            try {
              const response = await fetch(
                `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
                  contact.location_name
                )}`
              );
              const data = await response.json();
              if (data && data.length > 0) {
                return {
                  ...contact,
                  latitude: parseFloat(data[0].lat),
                  longitude: parseFloat(data[0].lon),
                };
              }
            } catch (error) {
              console.error("Geocoding error:", error);
            }
          }
          return contact;
        })
      );
      setGeoContacts(geocodedContacts.filter((c) => c.latitude && c.longitude));
      setNonGeoContacts(
        geocodedContacts.filter((c) => !c.latitude || !c.longitude)
      );
    };

    geocodeContacts();
  }, [contacts]);

  useEffect(() => {
    if (mapRef.current && geoContacts.length > 0) {
      if (markerClusterRef.current) {
        markerClusterRef.current.clearLayers();
      } else {
        markerClusterRef.current = L.markerClusterGroup({
          spiderfyOnMaxZoom: true,
          showCoverageOnHover: false,
          zoomToBoundsOnClick: true,
        });
        mapRef.current.addLayer(markerClusterRef.current);
      }

      const groupedContacts = geoContacts.reduce((acc, contact) => {
        const key = `${contact.latitude},${contact.longitude}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(contact);
        return acc;
      }, {});

      Object.entries(groupedContacts).forEach(([key, contacts], index) => {
        const [lat, lng] = key.split(",").map(Number);
        contacts.forEach((contact, i) => {
          const angle = (Math.PI * 2 * i) / contacts.length;
          const offsetDistance = 0.0001; // Adjust this value to increase/decrease the spread
          const newLat = lat + Math.cos(angle) * offsetDistance * (index + 1);
          const newLng = lng + Math.sin(angle) * offsetDistance * (index + 1);

          const marker = L.marker([newLat, newLng]);
          const popupContent = `
            <div>
              <h3>${contact.full_name}</h3>
              <p>Email: ${contact.email}</p>
              <p>Phone: ${contact.phone_number}</p>
              <p>Met in: ${contact.location_name}</p>
              <p>Date: ${contact.date_met}</p>
            </div>
          `;
          marker.bindPopup(popupContent);
          markerClusterRef.current.addLayer(marker);
        });
      });

      mapRef.current.fitBounds(markerClusterRef.current.getBounds());
    }
  }, [geoContacts]);

  const handleEditClick = (contactId) => {
    setEditingContactId(contactId);
  };

  const handleSave = async (contactId, newMetIn) => {
    console.log("Passing to updateContact:", {
      location_name: newMetIn,
      contactId,
    });
    updateContact(contactId, newMetIn);

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          newMetIn
        )}`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        const updatedContact = {
          ...contacts.find((c) => c.id === contactId),
          location_name: newMetIn,
          latitude: parseFloat(data[0].lat),
          longitude: parseFloat(data[0].lon),
        };
        setGeoContacts(
          geoContacts.map((c) => (c.id === contactId ? updatedContact : c))
        );
        setNonGeoContacts(nonGeoContacts.filter((c) => c.id !== contactId));
      } else {
        const updatedContact = {
          ...contacts.find((c) => c.id === contactId),
          location_name: newMetIn,
        };
        setNonGeoContacts(
          nonGeoContacts.map((c) => (c.id === contactId ? updatedContact : c))
        );
        setGeoContacts(geoContacts.filter((c) => c.id !== contactId));
      }
    } catch (error) {
      console.error("Geocoding error:", error);
    }

    setEditingContactId(null);
  };

  const mapStyle = {
    height: isMobile ? "300px" : "400px",
    width: "100%",
  };

  return (
    <Box>
      <MapContainer
        center={center}
        zoom={isMobile ? 3 : 4}
        style={mapStyle}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
      </MapContainer>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6">Contacts without location</Typography>
        <List>
          {nonGeoContacts.map((contact) => (
            <ListItem key={contact.id}>
              <Box>
                <Typography variant="subtitle1">{contact.full_name}</Typography>
                <Typography variant="body2">
                  Met in: {contact.location_name || "Not specified"}
                </Typography>
                {editingContactId === contact.id ? (
                  <EditForm contact={contact} onSave={handleSave} />
                ) : (
                  <Button
                    onClick={() => handleEditClick(contact.id)}
                    size="small"
                  >
                    {contact.location_name ? "Edit Met in" : "Add Met in"}
                  </Button>
                )}
              </Box>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default MapView;
