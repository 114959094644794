import React, { useState } from "react";
import { useUserContext } from "./userContext";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  ThemeProvider,
  useTheme,
  Alert,
} from "@mui/material";
import * as action from "../../service/action";
import LoadingButton from "@mui/lab/LoadingButton";
import { Google, Visibility, VisibilityOff } from "@mui/icons-material";
import bappaTap from "../../images/bappa-tap.png";
import bappaLogo from "../../images/bappa-logo-black.png";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const theme = useTheme();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const navigate = useNavigate();

  const { signInUser, sendVerification, signInWithGoogle } = useUserContext();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle().then(async (result) => {
        const user = result.user;
        if (user) {
          const backendUser = await action.getMe(user.accessToken);
          if (backendUser.id) {
            navigate("/dashboard");
          } else {
            const response = await action.createGmailUser(user.email);
            if (response.status !== 200) {
              setAlertMessage(
                response.data.error || "An error occurred during signup"
              );
            } else {
              setAlertMessage("Your account has been created successfully");
              setTimeout(() => {
                navigate("/dashboard");
              }, 2000);
            }
          }
        }
      });
    } catch (error) {
      setAlertMessage("Invalid Login");
      setAlertSeverity("error");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (email && password) {
      setIsLoading(true);
      const credentials = await signInUser(email, password);
      console.log(credentials);
      if (credentials) {
        if (!credentials.user.emailVerified) {
          await sendVerification(credentials.user);
          setAlertMessage(
            "A verification email has been sent. Please check your inbox."
          );
          setAlertSeverity("info");
        } else {
          navigate("/dashboard");
        }
      } else {
        setAlertMessage("Invalid Login");
        setAlertSeverity("error");
      }
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="lg"
        sx={{ height: "100vh", display: "flex", alignItems: "center" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
            BappaCards{" "}
            <img
              src={bappaLogo}
              alt="BappaLogo"
              style={{ width: "24px", height: "auto", marginLeft: "4px" }}
            />
          </Typography>
        </Box>
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box sx={{ maxWidth: 500, mx: "auto" }}>
              {alertMessage && (
                <Alert severity={alertSeverity} sx={{ mb: 2 }}>
                  {alertMessage}
                </Alert>
              )}
              <Box sx={{ mb: 4 }}>
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                    fontSize: {
                      xs: "2rem",
                      sm: "3rem",
                    },
                  }}
                >
                  Welcome back!
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  Log in to BappaCards
                </Typography>
              </Box>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={onSubmit}
                  loading={isLoading}
                  sx={{
                    mt: 3,
                    mb: 2,
                    bgcolor: theme.palette.primary.main,
                    "&:hover": { bgcolor: theme.palette.secondary.dark },
                  }}
                >
                  Log in
                </LoadingButton>
                <Divider sx={{ my: 2 }}>OR</Divider>
                <Button
                  onClick={handleGoogleSignIn}
                  fullWidth
                  variant="outlined"
                  startIcon={<Google />}
                  sx={{ mb: 2 }}
                >
                  Continue with Google
                </Button>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Link
                      component={RouterLink}
                      to="/reset-password"
                      variant="body2"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/reset-password");
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <Typography variant="body2">
                    Don&apos;t have an account?{" "}
                    <Link onClick={() => navigate("/sign-up")}>Sign up</Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#E6E6FA",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={bappaTap}
              alt="Login illustration"
            />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
