import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ManageProfile from "./ManageProfile";
import ManageProfile2 from "./ManageProfile2";
import { Stack } from "@mui/material";
import * as action from "../../service/action";

import { useUserContext } from "../Login/userContext";
const defaultTheme = createTheme();

const UserProfile = () => {
  const { getUser } = useUserContext();
  const [userData, setUserData] = useState(undefined);

  const fetchData = async () => {
    const token = (await getUser()?.getIdToken()) || "token";
    const userDataResponse = await action.getMe(token);
    setUserData(userDataResponse);
  };

  useEffect(() => {
    userData === undefined ? fetchData() : fetchData();
  }, []);

  if (!userData) {
    return;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Stack direction="row">
        <Container
          component="main"
          maxWidth="xl"
          sx={{ mb: 4, justifyContent: "left", alignItems: "baseline" }}
        >
          {/* <Typography component="h1" variant="h4" align="center">
            Manage Profile
          </Typography> */}
          <Container maxWidth="xl" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <React.Fragment>
                <ManageProfile2 userData={userData} />
              </React.Fragment>
            </Paper>
          </Container>
        </Container>
      </Stack>
    </ThemeProvider>
  );
};

export default UserProfile;
