import axios from "axios";

const DEV_API = "http://localhost:3000/api/v1";
const PROD_API = "https://api.bappacards.com/api/v1";

const API = PROD_API;

export const updateV3Contact = async (token, contactId, data) => {
  try {
    const response = await post(data, `contacts/${contactId}`, token);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not update contact: ${e}` };
  }
};

export const deleteV3Contact = async (token, contactId) => {
  try {
    const response = await deleteRequest(`contacts/${contactId}`, token);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not delete contact: ${e}` };
  }
};

export const createV3Contact = async (token, data) => {
  try {
    const response = await post(data, "contacts", token);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not create contact: ${e}` };
  }
};

export const createGmailUser = async (email) => {
  try {
    const response = await post({ email: email }, "users/gmail");
    return response;
  } catch (e) {
    return { status: 500, message: `Could not create gmail user: ${e}` };
  }
};

export const createUser = async (email, password, fullName = "") => {
  try {
    const response = await post(
      { email: email, password: password, fullName: fullName },
      "users"
    );
    return response;
  } catch (e) {
    return { status: 500, message: `Could not create user: ${e}` };
  }
};

export const createContact = async (profileId, body) => {
  try {
    const response = await post(body, `profiles/${profileId}/contacts`);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not create contact: ${e}` };
  }
};

export const createCard = async (token) => {
  const response = await post(undefined, "cards", token);
  return response.data;
};

export const getMe = async (token) => {
  try {
    const response = await get("users/me", undefined, token);
    return response.data;
  } catch (e) {
    return { status: 404, message: `Could not get user: ${e}` };
  }
};

export const getProfile = async (profileId, token) => {
  try {
    const response = await get(`profiles/${profileId}`, undefined, token);
    return response;
  } catch (e) {
    return { status: 404, message: `Could not get profile: ${e}` };
  }
};

export const getProfiles = async (token) => {
  try {
    const response = await get(`profiles`, undefined, token);
    return response;
  } catch (e) {
    return { status: 404, message: `Could not get profiles: ${e}` };
  }
};

export const registerCard = async (cardId, token) => {
  try {
    const response = post({ card_id: cardId }, "cards/register", token);
    return response;
  } catch (e) {
    return { status: 403, message: `Could not register card: ${e}` };
  }
};

export const updateContact = async (token, data) => {
  try {
    const response = await post(data, "users/contacts", token);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not update contact: ${e}` };
  }
};

export const getGroup = async (token, groupName) => {
  const response = await get(`groups/${groupName}`, undefined, token);
  return response.data;
};

export const updateGroup = async (token, groupName, data) => {
  const response = await post(data, `groups/${groupName}`, token);
  return response;
};

export const updateProfile = async (token, data) => {
  const response = await post(data, "users/links", token);
  return response;
};

export const updatePhoto = async (token, data) => {
  const response = await post(data, "users/links/photo", token, true);
  return response;
};

export const updateGroupPhoto = async (token, groupName, data) => {
  const response = await post(data, `groups/${groupName}/photo`, token, true);
  return response;
};

export const post = async (body, slug, token, isPhoto = false) => {
  let config = {};
  console.log(body);
  if (token && isPhoto) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
  } else if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  } else {
    config = { headers: { "Content-Type": "application/json" } };
  }

  return axios
    .post(`${API}/${slug}`, body, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteRequest = async (slug, token) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  return axios.delete(`${API}/${slug}`, config);
};

export const get = async (slug, query, token) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };

  if (query === undefined) {
    return axios
      .get(`${API}/${slug}`, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  } else {
    return axios
      .get(`${API}/${slug}?${query}`, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
};

export const someAction = (payload) => ({
  type: "SOME_ACTION",
  payload,
});
