import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  ButtonGroup,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as action from "../../service/action";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useUserContext } from "../Login/userContext";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import citiesData from "./cities.json"; // Adjust the path as necessary

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DashboardContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#f5f5f5",
  minHeight: "100vh",
});

const StatCard = styled(Paper)({
  padding: "15px",
  textAlign: "center",
  color: "#333",
  marginBottom: "15px",
  backgroundColor: "#fff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const Dashboard = () => {
  const { getUser } = useUserContext();
  const [userData, setUserData] = useState(undefined);
  const [profilesData, setProfilesData] = useState([]);
  const [mode, setMode] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [cityCoordinates, setCityCoordinates] = useState({});
  const mapRef = useRef(null);

  const fetchData = async () => {
    const token = (await getUser()?.getIdToken()) || "token";
    const userDataResponse = await action.getMe(token);
    const profilesData = await action.getProfiles(token);
    setUserData(userDataResponse);
    setProfilesData(profilesData.data);
  };

  useEffect(() => {
    userData === undefined ? fetchData() : fetchData();
  }, []);

  const fetchCoordinates = async (city) => {
    // Check if the city is in the local JSON file
    const cityData = citiesData.find(
      (c) => c.city.toLowerCase() === city.toLowerCase()
    );
    if (cityData) {
      console.log(`Using cached data for city: ${city}`);
      console.log(cityData.lat, cityData.lon);
      setCityCoordinates((prev) => ({
        ...prev,
        [city]: { lat: cityData.lat, lon: cityData.lon },
      }));
      return { lat: cityData.lat, lon: cityData.lon };
    }

    // If not found, fetch from OpenStreetMap API
    console.log(`Fetching coordinates for: ${city}`);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          city
        )}&limit=1`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        const { lat, lon } = data[0];
        // Optionally, you can add the new city to the JSON file here
        setCityCoordinates((prev) => ({
          ...prev,
          [city]: { lat: parseFloat(lat), lon: parseFloat(lon) },
        }));
        return { lat: parseFloat(lat), lon: parseFloat(lon) };
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
    return null;
  };

  if (!userData || profilesData.length === 0) {
    return <LoadingScreen />;
  }

  const citiesArray = Object.entries(profilesData.scan_data.scans_by_city).sort(
    (a, b) => (a[1] > b[1] ? -1 : 1)
  );

  const citiesArrayToday = Object.entries(
    profilesData.scan_data.scans_by_city_today
  ).sort((a, b) => (a[1] > b[1] ? -1 : 1));

  const citiesArrayThisWeek = Object.entries(
    profilesData.scan_data.scans_by_city_this_week
  ).sort((a, b) => (a[1] > b[1] ? -1 : 1));

  const getCityData = (mode) => {
    if (mode === 0) {
      return citiesArray;
    } else if (mode === 50) {
      return citiesArrayThisWeek;
    } else {
      return citiesArrayToday;
    }
  };

  const getUserTapsTitle = (mode) => {
    switch (mode) {
      case 0:
        return "All time Taps by Users";
      case 50:
        return "Taps by Users This Week";
      case 100:
        return "Taps by Users Today";
      default:
        return "Taps by Users";
    }
  };

  const mapStyle = {
    height: "400px",
    width: "100%",
    marginBottom: "20px",
  };

  const getSorting = (a, b) => {
    if (mode === 0) {
      return a.amount_scanned > b.amount_scanned ? -1 : 1;
    } else if (mode === 50) {
      return a.amount_scanned_this_week > b.amount_scanned_this_week ? -1 : 1;
    }
    return a.amount_scanned_today > b.amount_scanned_today ? -1 : 1;
  };

  const getAmountScanned = (profile, mode) => {
    if (mode === 0) {
      return profile.amount_scanned;
    } else if (mode === 50) {
      return profile.amount_scanned_this_week;
    }
    return profile.amount_scanned_today;
  };

  return (
    <DashboardContainer>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem" }, color: "#333" }}
      >
        Dashboard
      </Typography>

      <ButtonGroup
        variant="contained"
        sx={{
          mb: 4,
          "& .MuiButton-root": {
            backgroundColor: "#1976d2",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#115293",
            },
            "&.Mui-selected": {
              backgroundColor: "#115293",
            },
          },
        }}
      >
        <Button onClick={() => setMode(0)}>All Time</Button>
        <Button onClick={() => setMode(50)}>This Week</Button>
        <Button onClick={() => setMode(100)}>Today</Button>
      </ButtonGroup>

      <MapContainer center={[40, -95]} zoom={4} style={mapStyle} ref={mapRef}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {getCityData(mode).map(([city, count], index) => {
          const coordinates = cityCoordinates[city];
          if (!coordinates) {
            fetchCoordinates(city);
            return null;
          }
          return (
            <Marker key={index} position={[coordinates.lat, coordinates.lon]}>
              <Popup>
                <Typography variant="subtitle1">{city}</Typography>
                <Typography variant="body2">{count} taps</Typography>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} md={3}>
          <StatCard>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" }, color: "#555" }}
            >
              Total Profiles
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: "1.5rem", sm: "2rem" }, color: "#1976d2" }}
            >
              {profilesData.profiles.length}
            </Typography>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard>
            <Typography variant="h6" sx={{ color: "#555" }}>
              Total Taps
            </Typography>
            <Typography variant="h4" sx={{ color: "#1976d2" }}>
              {mode === 0
                ? profilesData.scan_data.total_scans
                : mode === 50
                ? profilesData.scan_data.total_scans_this_week
                : profilesData.scan_data.total_scans_today}
            </Typography>
          </StatCard>
        </Grid>
      </Grid>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Paper
            sx={{
              padding: "15px",
              marginBottom: "15px",
              backgroundColor: "#fff",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" sx={{ color: "#333" }}>
                  {mode === 0
                    ? "All time Taps by City"
                    : mode === 50
                    ? "Taps by City This Week"
                    : "Taps by City Today"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getCityData(mode).map(([city, count], index) => (
                    <Grid item xs={6} sm={4} md={3} key={index}>
                      <StatCard>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: { xs: "0.8rem", sm: "0.9rem" },
                            color: "#555",
                          }}
                        >
                          {city}
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{
                            fontSize: { xs: "1.2rem", sm: "1.5rem" },
                            color: "#1976d2",
                          }}
                        >
                          {count}
                        </Typography>
                      </StatCard>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6" sx={{ color: "#333" }}>
                {getUserTapsTitle(mode)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  sx={{ mb: 2 }}
                  InputProps={{
                    sx: {
                      borderRadius: 0,
                    },
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <List>
                  {profilesData.profiles
                    .filter((profile) => {
                      return (
                        profile.firstname
                          ?.toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        profile.lastname
                          ?.toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      );
                    })
                    .sort((a, b) => {
                      return getSorting(a, b);
                    })
                    .map((profile) => (
                      <ListItem
                        hidden={!profile.email}
                        key={profile.profile_id}
                        button
                        onClick={() =>
                          window
                            .open(
                              profile.group
                                ? `${window.location.origin}/profile/${profile.profile_id}?group=${profile.group}`
                                : `${window.location.origin}/profile/${profile.profile_id}`,
                              "_blank"
                            )
                            .focus()
                        }
                      >
                        <ListItemText
                          primary={`${profile.firstname} ${
                            profile.lastname
                          } : ${profile.email}: ${getAmountScanned(
                            profile,
                            mode
                          )} taps`}
                        />
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default Dashboard;
