import React, { useState } from "react";
import { useUserContext } from "../Login/userContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button } from "reactstrap";
import * as action from "../../service/action";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import FileSaver from "file-saver";
import Lottie from "lottie-react";
import saveContact from "../../assets/lottie/save-contact.json";
import contactLogo from "../../images/contact-icon.png";
import { blue } from "@mui/material/colors";
import { generateVCard } from "../../service/vcard";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const lighterBlue = blue[700];

const getFontSize = (textLength) => {
  const width = window.innerWidth / 14;
  const baseSize = width;

  if (textLength >= baseSize) {
    textLength = baseSize - 8;
  }
  const fontSize = baseSize - textLength;

  if (fontSize < 40) {
    return `${fontSize}px`;
  } else {
    return "40px";
  }
};

const getImageSize = () => {
  const size = window.innerWidth / 15;

  if (size < 40) {
    return size;
  } else {
    return 40;
  }
};

const getPaddingSize = () => {
  const size = window.innerWidth / 30;

  if (size < 40) {
    return size;
  } else {
    return 40;
  }
};

export const downloadVcard = async (links) => {
  const vcard = await generateVCard(links);
  const blob = new Blob([vcard], {
    type: "text/vcard;charset=utf-8",
  });
  FileSaver.saveAs(blob, "vcard.vcf");
};

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  maxWidth: 400,
  width: "90%",
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  fontWeight: "bold",
  color: theme.palette.primary.main,
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  fontSize: "1rem",
  fontWeight: "bold",
  textTransform: "none",
}));

const SaveContact = ({ open, handleClose, profile, openExchangeContact }) => {
  const handleSaveContact = async () => {
    await downloadVcard(profile.links);
    handleClose();
    openExchangeContact(`Share your information back with ${profile.name}`);
  };

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox>
        <StyledTitle variant="h5" align="center">
          Save Contact
        </StyledTitle>
        <Stack direction="column" spacing={3} alignItems="center">
          <Lottie
            animationData={saveContact}
            loop={true}
            autoPlay={true}
            style={{ width: "80%", maxWidth: 250 }}
          />
          {profile.preferences.showSaveContactButton && (
            <StyledButton
              onClick={handleSaveContact}
              startIcon={
                <img
                  alt="save"
                  src={contactLogo}
                  style={{ width: 24, height: 24 }}
                />
              }
            >
              Save Contact
            </StyledButton>
          )}
        </Stack>
      </StyledBox>
    </StyledModal>
  );
};

export default SaveContact;
