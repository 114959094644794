import React, { useState, useEffect } from "react";
import * as action from "../../service/action";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../Login/userContext";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  ThemeProvider,
  useTheme,
  Snackbar,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Google, Visibility, VisibilityOff } from "@mui/icons-material";
import bappaTap from "../../images/bappa-tap.png";
import bappaLogo from "../../images/bappa-logo-black.png";

export default function SignupPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState({
    isStrong: false,
    message: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { signInWithGoogle } = useUserContext();

  useEffect(() => {
    setPasswordsMatch(password === confirmPassword || confirmPassword === "");
    checkPasswordStrength(password);
  }, [password, confirmPassword]);

  const checkPasswordStrength = (pwd) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(pwd);
    const hasLowerCase = /[a-z]/.test(pwd);
    const hasNumbers = /\d/.test(pwd);
    const hasNonalphas = /\W/.test(pwd);

    if (pwd.length < minLength) {
      setPasswordStrength({
        isStrong: false,
        message: "Password must be at least 8 characters long",
      });
    } else if (!(hasUpperCase && hasLowerCase && hasNumbers && hasNonalphas)) {
      setPasswordStrength({
        isStrong: false,
        message:
          "Password must contain uppercase, lowercase, numbers, and special characters",
      });
    } else {
      setPasswordStrength({ isStrong: true, message: "Strong password" });
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleGoogleSignUp = async () => {
    await signInWithGoogle().then(async (result) => {
      try {
        const user = result.user;
        if (user) {
          const response = await action.createGmailUser(user.email);
          if (response.status !== 200) {
            setAlertMessage(
              response.data.error || "An error occurred during signup"
            );
            setOpenAlert(true);
          } else {
            setAlertMessage("Your account has been created successfully");
            setOpenAlert(true);
            setTimeout(() => {
              navigate("/log-in");
            }, 2000);
          }
        }
      } catch (error) {
        setAlertMessage(error.message || "An unexpected error occurred");
        setOpenAlert(true);
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!passwordsMatch) {
      setAlertMessage("Passwords do not match");
      setOpenAlert(true);
      setIsLoading(false);
      return;
    }

    if (!passwordStrength.isStrong) {
      setAlertMessage(passwordStrength.message);
      setOpenAlert(true);
      setIsLoading(false);
      return;
    }

    if (email && password && confirmPassword) {
      try {
        const response = await action.createUser(email, password);

        if (response.status !== 200) {
          setAlertMessage(
            response.data.error || "An error occurred during signup"
          );
          setOpenAlert(true);
        } else {
          setAlertMessage("Your account has been created successfully");
          setOpenAlert(true);
          setTimeout(() => {
            navigate("/log-in");
          }, 2000);
        }
      } catch (error) {
        setAlertMessage(error.message || "An unexpected error occurred");
        setOpenAlert(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="lg"
        sx={{ height: "100vh", display: "flex", alignItems: "center" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
            BappaCards{" "}
            <img
              src={bappaLogo}
              alt="BappaLogo"
              style={{ width: "24px", height: "auto", marginLeft: "4px" }}
            />
          </Typography>
        </Box>
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box sx={{ maxWidth: 500, mx: "auto" }}>
              <Box sx={{ mb: 4 }}>
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                    fontSize: {
                      xs: "2rem",
                      sm: "3rem",
                    },
                  }}
                >
                  Join BappaCards
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  Sign up for free!
                </Typography>
              </Box>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                  error={!passwordsMatch || !passwordStrength.isStrong}
                  helperText={
                    !passwordsMatch
                      ? "Passwords do not match"
                      : passwordStrength.message
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="new-password"
                  error={!passwordsMatch}
                  helperText={!passwordsMatch ? "Passwords do not match" : ""}
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={onSubmit}
                  loading={isLoading}
                  sx={{
                    mt: 3,
                    mb: 2,
                    bgcolor: theme.palette.primary.main,
                    "&:hover": { bgcolor: theme.palette.secondary.dark },
                  }}
                >
                  Sign up
                </LoadingButton>
                <Divider sx={{ my: 2 }}>OR</Divider>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<Google />}
                  sx={{ mb: 2 }}
                  onClick={handleGoogleSignUp}
                >
                  Sign up with Google
                </Button>
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <Typography variant="body2">
                    Already have an account?{" "}
                    <Link onClick={() => navigate("/log-in")}>Log in</Link>
                  </Typography>
                </Box>
                <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                  By clicking Sign up, you agree to BappaCards's{" "}
                  <Link href="#" underline="always">
                    privacy notice
                  </Link>
                  ,{" "}
                  <Link href="#" underline="always">
                    T&Cs
                  </Link>{" "}
                  and to receive offers, news and updates.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#E6E6FA",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={bappaTap}
              alt="Signup illustration"
            />
          </Grid>
        </Grid>
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={
              alertMessage.includes("successfully") ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}
