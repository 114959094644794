import VCard from "vcard-creator";

export const generateVCard = async (links) => {
  const newVCard = new VCard();
  if (links.lastname || links.firstname) {
    newVCard.addName(links.lastname, links.firstname);
  }

  if (links.title) {
    newVCard.addJobtitle(links.title);
    newVCard.addRole(links.title);
  }

  if (links.email) {
    newVCard.addEmail(links.email);
  }

  if (links.phone_number) {
    newVCard.addPhoneNumber(links.phone_number);
  }

  if (links.website_urls.length > 0) {
    for (let website_url of links.website_urls) {
      newVCard.addURL(website_url);
    }
  }

  if (links.instagram_usernames.filter((item) => item !== "").length > 0) {
    for (let instagram_username of links.instagram_usernames) {
      newVCard.addSocial(
        `https:/instagram.com/${instagram_username}`,
        "Instagram",
        instagram_username
      );
    }
  }

  if (links.twitter_usernames.filter((item) => item !== "").length > 0) {
    for (let twitter_username of links.twitter_usernames) {
      newVCard.addSocial(
        `https:/twitter.com/${twitter_username}`,
        "Twitter",
        twitter_username
      );
    }
  }

  if (links.snapchat_usernames.filter((item) => item !== "").length > 0) {
    for (let snapchat_username of links.snapchat_usernames) {
      newVCard.addSocial(
        `https://snapchat.com/add/${snapchat_username}`,
        "Snapchat",
        snapchat_username
      );
    }
  }

  if (links.tiktok_usernames.filter((item) => item !== "").length > 0) {
    for (let tiktok_username of links.tiktok_usernames) {
      newVCard.addSocial(
        `https:/tiktok.com/@${tiktok_username}`,
        "TikTok",
        tiktok_username
      );
    }
  }

  if (links.facebook_urls.filter((item) => item !== "").length > 0) {
    for (let facebook_url of links.facebook_urls) {
      newVCard.addSocial(facebook_url, "Facebook", facebook_url);
    }
  }

  if (links.youtube_urls.filter((item) => item !== "").length > 0) {
    console.log("youtube_urls");
    console.log(links.youtube_urls);
    for (let youtube_url of links.youtube_urls) {
      newVCard.addSocial(youtube_url, "Youtube", youtube_url);
    }
  }

  if (links.linkedin_urls.filter((item) => item !== "").length > 0) {
    for (let linkedin_url of links.linkedin_urls) {
      newVCard.addSocial(linkedin_url, "LinkedIn", linkedin_url);
    }
  }

  if (links.whatsapp_numbers.filter((item) => item !== "").length > 0) {
    for (let whatsapp_number of links.whatsapp_numbers) {
      newVCard.addSocial(
        `https://wa.me/${whatsapp_number}`,
        "WhatsApp",
        whatsapp_number
      );
    }
  }

  if (links.cashapp_usernames.filter((item) => item !== "").length > 0) {
    for (let cashapp_username of links.cashapp_usernames) {
      newVCard.addSocial(
        `https://cash.app/${cashapp_username}`,
        "CashApp",
        cashapp_username
      );
    }
  }

  if (links.paypal_usernames.filter((item) => item !== "").length > 0) {
    for (let paypal_username of links.paypal_usernames) {
      newVCard.addSocial(
        `https://paypal.me/${paypal_username}`,
        "Paypal",
        paypal_username
      );
    }
  }

  if (links.venmo_usernames.filter((item) => item !== "").length > 0) {
    for (let venmo_username of links.venmo_usernames) {
      newVCard.addSocial(
        `https://venmo.com/${venmo_username}`,
        "Venmo",
        venmo_username
      );
    }
  }

  newVCard.addSocial(
    `https://bappacards.com/${links.profile_id}`,
    "BappaCards"
  );

  if (links.profile_photo) {
    await photoURIToBase64(links.profile_photo).then((blob) => {
      newVCard.addPhoto(blob, "JPEG");
    });
  }

  return newVCard.toString();
};

// Function to convert a photo URI to a Blob using fetch
async function photoURIToBase64(photoURI) {
  try {
    // Fetch the photo URI
    const response = await fetch(photoURI);

    // Check if the request was successful (status code 200)
    if (!response.ok) {
      throw new Error(`Error loading photo: ${response.statusText}`);
    }

    // Convert the response data to a base64-encoded string
    const bufferArray = await response.arrayBuffer();
    let TYPED_ARRAY = new Uint8Array(bufferArray);
    const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, "");

    let base64String = btoa(STRING_CHAR);

    console.log(base64String);
    return base64String;
  } catch (error) {
    console.error(`Error loading photo: ${error.message}`);
    throw error; // Rethrow the error for handling elsewhere if needed
  }
}

export const generatVCardForContact = async (contact) => {
  const newVCard = new VCard();
  if (contact.name.includes(" ")) {
    newVCard.addName(contact.name.split(" ")[1], contact.name.split(" ")[0]);
  } else {
    newVCard.addName(contact.name, "");
  }

  if (contact.email) newVCard.addEmail(contact.email);
  if (contact.phone) newVCard.addPhoneNumber(contact.phone);
  if (contact.notes) newVCard.addNote(contact.notes);

  return newVCard.toString();
};
